(function(app) {
    'use strict';

    app.component('cmReport', {
        templateUrl: '/js/components/cmReport/report.html',
        bindings: {
            task: '=',
        },
        controllerAs: 'vm',
        controller: function(_apiService, _view, _i18n, Notification) {
            var vm = this;
            vm.isLoading = true;
            _apiService.taskReport2(vm.task.id, _view.getCurrentAccount()).then(function (r) {
                vm.isLoading = false;
                vm.report = r.data;
            }, function () {
                Notification.error(_i18n.getString("common.notifications.apiError"));
                vm.isLoading = false;
            });

        }
    });

})(angular.module('app'));
